<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="card-title w-100 d-flex justify-content-between">
        <h1>Konular </h1>
        <a href="javascript:void(0)"
           data-bs-toggle="modal"
           @click="topic = {}"
           data-bs-target="#add_modal" class="btn btn-primary">
          <span class="svg-icon svg-icon-1"><i class="fa fa-plus"></i> </span>Yeni</a>
      </div>
    </div>
    <div class="card-body pt-0 pb-5">
      <veri-tablo :datas="datas" :headers="tableHeader" :links="links" :metas="metas">
        <template v-slot:cell-title="{ row: item }">
            <div class="d-flex  flex-column ps-7">
              <a href="#" class="text-dark fw-bolder text-hover-primary fs-6 text-start">{{ item.title }}</a>
              <span class="text-muted fw-bold text-muted d-block fs-7 text-start"> {{ item.explanation.slice(0, 40) }}...</span>
            </div>
        </template>
        <template v-slot:cell-tag="{ row: item }">
          <el-tag v-for="(tag, i) in item.tags" :key="i" class="ms-2">{{ tag }}</el-tag>
        </template>
        <template v-slot:cell-actions="{row:item}">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link rounded p-3 bg-light">İşlemler<el-icon class="el-icon--right"><arrow-down/></el-icon></span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="topic = item">
                  <a href="javascript:void(0)" class="text-hover-primary" data-bs-toggle="modal"
                     data-bs-target="#update_modal"> Güncelle </a>
                </el-dropdown-item>
                <el-dropdown-item @click="deleteItem(item)">Sil</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </veri-tablo>
    </div>
  </div>
  <empty-modal modalId="add_modal" ref="add_modal__ref" modalTitle="Konu Ekle">
    <template v-slot:ModalBody>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column mb-8">
                <label class="required fs-6 fw-bold mb-2">Başlık</label>
                <input v-model="title" type="text" class="form-control form-control-solid" placeholder="Başlık"
                       aria-label="Başlık"/>
              </div>
              <div class="d-flex flex-column mb-8">
                <label class="required fs-6 fw-bold mb-2">Açıklama
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="this is content, this is content, this is content">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                         data-bs-original-title="Specify a target priorty" aria-label="Specify a target priorty"></i>
                    </template>
                  </el-popover>
                </label>
                <textarea v-model="explanation" class="form-control form-control-solid" rows="6" cols="6"
                          placeholder="Açıklama" aria-label="Açıklama"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex flex-column mb-8 fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span class="required">Etiket</span>
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="this is content, this is content, this is content">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                    </template>
                  </el-popover>
                </label>
                <el-select
                    v-model="tags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :reserve-keyword="false"
                    placeholder="Etiket seçin veya yeni oluşturun"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.tagId"
                      :label="item.title"
                      :value="item.tagId"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:ModalFooter>
      <a @click="save()" class="btn btn-primary">Kaydet</a>
    </template>
  </empty-modal>
  <empty-modal modalId="update_modal" ref="update_modal__ref" modalTitle="Konu Güncelle">
    <template v-slot:ModalBody>
    
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-column mb-8">
                <label class="required fs-6 fw-bold mb-2">Başlık</label>
                <input v-model="topic.title" type="text" class="form-control form-control-solid" placeholder="Başlık"
                       aria-label="Başlık"/>
              </div>
              <div class="d-flex flex-column mb-8">
                <label class="required fs-6 fw-bold mb-2">Açıklama
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="this is content, this is content, this is content">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                         data-bs-original-title="Specify a target priorty" aria-label="Specify a target priorty"></i>
                    </template>
                  </el-popover>
                </label>
                <textarea v-model="topic.explanation" class="form-control form-control-solid" rows="6" cols="6"
                          placeholder="Açıklama" aria-label="Açıklama"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex flex-column mb-8 fv-row">
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span class="required">Etiket</span>
                  <el-popover placement="top-start" title="Title" :width="200" trigger="hover"
                              content="this is content, this is content, this is content">
                    <template #reference>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""
                         data-bs-original-title="Specify a target priorty" aria-label="Specify a target priorty"></i>
                    </template>
                  </el-popover>
                </label>
                <el-select
                    v-model="topic.tags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :reserve-keyword="false"
                    placeholder="Etiket seçin veya yeni oluşturun"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.tagId"
                      :label="item.title"
                      :value="item.tagId"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>
    <template v-slot:ModalFooter>
      <a @click="update()" class="btn btn-primary"> Kaydet </a>
      <button @click="update_modal__ref.close()" class="btn btn-danger"> Kapat</button>
    </template>
  </empty-modal>
</template>
<script>

import {useStore} from "vuex";
import {ArrowDown, Plus} from '@element-plus/icons-vue';
import {computed, onMounted, reactive, ref} from "vue";
import VeriTablo from '@/components/tablo/VeriTablo.vue'
import EmptyModal from '@/components/modals/forms/EmptyModal.vue';
import {useRouter} from "vue-router";
import {inject} from 'vue';
import useCruds from '@/composables/useCrud.js';
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";


export default {
  components: {
    VeriTablo,
    EmptyModal,
    ArrowDown
  },
  setup() {


    const tags = ref();
    const options = ref();


    const title = ref('');
    const explanation = ref('');
    const topic = ref({title: '', explanation: ''});
    const payload = ref({slug: "/topics", data: topic.value})

    const add_modal__ref = ref();
    const update_modal__ref = ref();

    const imageUrl = ref('');
    const {saveCrud, updateCrud, deleteCrud, loadCrud} = useCruds();

    const tableHeader = [
      {
        name: "Konu",
        key: 'title'
      },
      {
        name: "Etiket",
        key: "tag",
      },
      {
        name: "İşlemler",
        key: "actions",
      },
    ];


    const store = useStore();
    const router = useRouter();

    const datas = ref({});
    const metas = ref({});
    const links = ref({});

    const save = () => {
      saveCrud({slug:'topics',data:{title:title.value,explanation:explanation.value,}}).then(
          function (value) {
            datas.value.unshift(value.data);
            add_modal__ref.value.close();
          }
      );
    };
    const update = () => {

      console.log(topic.value);

      let t = {
        title: topic.value.title,
        explanation: topic.value.explanation,
      }
      updateCrud({slug:"/topics/" + topic.value.topicId, data:t}).then(
          function (value) {
            update_modal__ref.value.close();
          }
      );
    };
    const emitter = inject('emitter');

    const deleteItem = (item) => {
      //payload.value.slug = "/topics/" + item.topicId;
      deleteCrud({slug:"/topics/" + item.topicId}).then(function (value) {
        if (value) {
          for (let i = 0; i < datas.value.length; i++) {
            if (datas.value[i] === item) {
              datas.value.splice(i, 1);
            }
          }
        }
      });

    };

    function loadPicture(file) {

      let img = document.getElementById('img');
      var reader = new FileReader();
      reader.onload = function (e) {
        imageUrl.value = e.target.result;
      }
      reader.readAsDataURL(file);
    }

    const onImageChange = (file) => {
      loadPicture(file.raw);
    };



    onMounted(() => {

      setCurrentPageBreadcrumbs("Genel Bakış", ["Konular"]);

      loadCrud('topics').then(function (response) {
        if (response.success) {
          datas.value = response.data;
          links.value = response.links;
          metas.value = response.meta;
        }
      });
      loadCrud('tags').then(function (response) {
        options.value = response.data;
      });
      emitter.on('PageButtonClicked', (value) => {
        if (router.currentRoute.value.path == "/topics") {
          loadCrud('/topics', value).then(function (response) {
            if (response.success) {
              datas.value = response.data;
              links.value = response.links;
              metas.value = response.meta;
            }
          });
        }
      });
    });

    return {
      tableHeader,
      datas,
      topic,
      deleteItem,
      save,
      update,
      links,
      metas,
      add_modal__ref,
      update_modal__ref,
      imageUrl,
      onImageChange,
      tags,
      options,
      title,
      explanation,
    }
  }
}
</script>
<style>

</style>